@media print {
    .id-card:nth-child(10n) {
        page-break-after: always;
    }
}

/* <uniquifier>: Use a unique and descriptive class name
<weight>: Use a value from 100 to 900 */

.roboto-condensed-400 {
  font-family: "Roboto Condensed", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
.roboto-condensed-100 {
  font-family: "Roboto Condensed", sans-serif;
  font-optical-sizing: auto;
  font-weight: 100;
  font-style: normal;
}
